.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*.fileDrop{*/
/*  height: 150px;*/
/*}*/

/*.fileDrop:before{*/
/*  content: "Drop file here";*/
/*  display: flex;*/
/*  width: 100%;*/
/*  height: 140px;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  border:dashed 2px #1976d2;*/
/*  border-radius: 10px;*/
/*  box-sizing: border-box;*/
/*  cursor: pointer;*/
/*  color: #1976d2;*/
/*  margin: 5px 0;*/
/*}*/

/*.fileDrop:hover:before{*/
/*  background-color: #cce2ff;*/
/*}*/