.image-picker{
    position: absolute;
    z-index: 3;
    background: #ffffff;
    width: calc(100% - 400px);
    /*border: solid;*/
    top: 10vh;
}

.image-picker-content{
    height: 80vh;
    overflow-y:auto;
}